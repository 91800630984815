/* eslint-disable sort-imports-es6-autofix/sort-imports-es6 */
import mParticle, { IdentityApiData, SDKEventAttrs } from "@mparticle/web-sdk";
import brazeKit from "@mparticle/web-braze-kit";
import mixpanelKit from "@mparticle/web-mixpanel-kit";
import facebookKit from "@mparticle/web-facebook-kit";
/* eslint-enable sort-imports-es6-autofix/sort-imports-es6 */

const mParticleConfig = {
  isDevelopmentMode: !import.meta.env.PROD,
  identityCallback: function (result) {
    // console.log('mparticle', 'identityCallback', result);
    // if (
    //   result &&
    //   result.body &&
    //   result.body.matched_identities &&
    //   result.body.matched_identities.email
    // ) {
    //    handle matched identity
    // }
  },
};

mixpanelKit.register(mParticleConfig);
brazeKit.register(mParticleConfig);
facebookKit.register(mParticleConfig);

mParticle.init(import.meta.env.VITE_MPARTICLE_API_KEY, mParticleConfig);

type mParticleLoginParam = {
  email?: string | null;
  userId?: string | null;
  phoneNumber?: string | null;
};
export const login = ({ email, userId, phoneNumber }: mParticleLoginParam) =>
  new Promise((resolve) => {
    const identityRequest: IdentityApiData = {
      userIdentities: {},
    };

    if (email) identityRequest.userIdentities.email = email;
    if (userId) identityRequest.userIdentities.customerid = userId;
    if (phoneNumber) identityRequest.userIdentities.mobile_number = phoneNumber;

    const identityCallback = function (result) {
      if (result.getUser()) {
        const mParticleUser = result.getUser();
        if (mParticleUser && phoneNumber) {
          mParticleUser.setUserAttribute("$Mobile", phoneNumber);
        }
        resolve(result);
        return;
      }

      const codes = window.mParticle.Identity.HTTPCodes;
      switch (result.httpCode) {
        case codes.noHttpCoverage:
          // retry the IDSync request
          break;
        case codes.activeIdentityRequest:
        case 429:
          //inspect your implementation if this occurs frequency
          //otherwise retry the IDSync request
          break;
        case codes.validationIssue:
        case 400:
          // inspect result.body to determine why the request failed
          // this typically means an implementation issue
          console.log(result.body);
          break;
        default:
          console.log(result.body);
      }
      resolve(result);
    };

    mParticle.Identity.login(identityRequest, identityCallback);
  });

export const logout = () => {
  const identityCallback = function (result) {
    if (result.getUser()) {
      //proceed with logout
    }
  };
  mParticle.Identity.logout({}, identityCallback);
};

export const sendCustomEvent = (data: SDKEventAttrs) => {
  const { action } = data;
  delete data.action;
  if (action) {
    mParticle.logEvent(action.toString(), mParticle.EventType.Navigation, {
      page: window.location.toString(),
      "referring-page": document.referrer,
      ...data,
    });
  }
};

export const trackPageView = () => {
  mParticle.logPageView(window.document.title, {
    page: window.location.toString(),
    "referring-page": document.referrer,
  });
};

export default {
  trackPageView,
  sendCustomEvent,
};
